export const Tick = ({
  width = 13,
  height = 11,
}: {
  width?: number;
  height?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5965 0.389671L4.4365 7.29967L2.5365 5.26967C2.1865 4.93967 1.6365 4.91967 1.2365 5.19967C0.846504 5.48967 0.736503 5.99967 0.976503 6.40967L3.2265 10.0697C3.4465 10.4097 3.8265 10.6197 4.2565 10.6197C4.6665 10.6197 5.0565 10.4097 5.2765 10.0697C5.6365 9.59967 12.5065 1.40967 12.5065 1.40967C13.4065 0.489671 12.3165 -0.320329 11.5965 0.379671V0.389671Z"
      fill="#10756B"
    />
  </svg>
);
